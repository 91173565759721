












































































import { Vue, Component, Prop, Watch } from 'vue-class-decorator'
import { GlobalModule } from '@/store'

@Component({
})
export default class CommentsComponent extends Vue {
  @Prop({ default: [] }) readonly schedules!: Array<object>

  @GlobalModule.Getter('getLoading') loading!: boolean

  remove(id: number) {
    this.$emit('remove', id)
  }

  edit(id: number) {
    this.$emit('edit', id)
  }
}
